import {RootStore} from '../../../stores/RootStore';
import {getSubscriptionOptions} from '../../product-service/subscriptions/getSubscriptionOptions';
import {getSubscriptionValidation} from '../../validator-service/getSubscriptionValidation';
import {SPECS} from '../../../../../specs';
import {makeAutoObservable} from 'mobx';
import {RootWidgetComponentIds} from '../../../config/constants';

export const bindSubscriptionWidgetProps = (rootStore: RootStore) => {
  const isMS3 = rootStore.controllerParams.flowAPI.experiments.enabled(SPECS.PRODUCT_PAGE_BLOCKS_MS3);
  const subscriptionsStore = makeAutoObservable({
    get items() {
      return getSubscriptionOptions(rootStore);
    },
    get isEmpty() {
      return subscriptionsStore.items.length === 0;
    },
    get validation() {
      return getSubscriptionValidation(rootStore);
    },
  });
  const {$bind} = rootStore.controllerParams;
  return $bind(RootWidgetComponentIds.Subscription, {
    options: () => subscriptionsStore.items,
    isError: () => subscriptionsStore.validation.isError,
    errorMessage: () => subscriptionsStore.validation.errorMessage,
    deleted: () => !isMS3 || subscriptionsStore.isEmpty,
  });
};

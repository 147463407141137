import model from './model';
import {StockIndicatorStates, StockIndicatorWidgetComponentIds as ComponentIds} from './config/constants';
import {initLocaleKeys} from '../../services/platform-service/initLocaleKeys';
import {autorun, makeAutoObservable} from 'mobx';

export default model.createController((controllerParams) => {
  const {$w, $props, flowAPI, $bindAll} = controllerParams;
  const t = initLocaleKeys(flowAPI.translations.i18n);

  const $widgetState = makeAutoObservable({
    get isStockIndicator() {
      return $props.settings.activeState === StockIndicatorStates.StockIndicator && $props.settings.showStockIndicator;
    },
    get isOutOfStock() {
      return $props.settings.activeState === StockIndicatorStates.OutOfStock;
    },
  });

  return {
    pageReady: () => {
      $bindAll({
        [ComponentIds.OutOfStockContainer]: {
          deleted: () => !$widgetState.isOutOfStock,
        },
        [ComponentIds.StockIndicatorContainer]: {
          deleted: () => !$widgetState.isStockIndicator,
        },
        [ComponentIds.StockIndicatorText]: {
          text: () =>
            t.productPage.lowStockMessage.label({
              Inventory: $props.data.amountInStock,
            }),
        },
        [ComponentIds.OutOfStockText]: {
          text: () => $props.settings.outOfStockText ?? t.productPage.outOfStockMessage.label(),
        },
      });
      autorun(() => {
        void $w(ComponentIds.MultiStateBox).changeState(
          $props.settings.activeState ?? StockIndicatorStates.StockIndicator,
        );
      });
    },
    exports: {},
  };
});

import {makeAutoObservable} from 'mobx';
import {RootWidgetComponentIds} from '../../../config/constants';
import {RootStore} from '../../../stores/RootStore';
import {getProductOptionsWithValidation} from '../../product-service/options/getProductOptionsWithValidation';
import {SPECS} from '../../../../../specs';

export const bindOptionsRepeaterWidgetProps = (rootStore: RootStore) => {
  const {$bind, flowAPI} = rootStore.controllerParams;
  const isMS3 = flowAPI.experiments.enabled(SPECS.PRODUCT_PAGE_BLOCKS_MS3);

  const optionsStore = makeAutoObservable({
    get optionsWithValidation() {
      return getProductOptionsWithValidation(rootStore);
    },
  });

  return $bind(RootWidgetComponentIds.OptionsNew, {
    deleted: () => !isMS3 || optionsStore.optionsWithValidation.length === 0,
    productOptions: () => optionsStore.optionsWithValidation as any,
    selectionIds: () => rootStore.$state.selectionIds,
  });
};

import type {RichContent} from '@wix/ricos';
import {isRichContent} from '@wix/ricos';

export const isRicoRichContent = (content: string) => {
  return getRicoRichContent(content) !== null;
};
export const getRicoRichContent = (content: string) => {
  try {
    const richContent = JSON.parse(content) as RichContent;
    return isRichContent(richContent) ? richContent : null;
  } catch {
    return null;
  }
};

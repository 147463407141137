export const interruptAction = async (interruptFunction: () => Promise<void>, action: () => any) => {
  try {
    if (interruptFunction) {
      await interruptFunction();
    }
  } catch (e) {
    if (e === 'cancel') {
      return;
    }

    throw e;
  }

  return action();
};

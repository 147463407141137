import model from './model';
import {ErrorMessageWidgetComponentIds as ComponentIds} from './config/constants';

export default model.createController((controllerParams) => {
  const {$bindAll, $props, $w} = controllerParams;
  return {
    pageReady: () => {
      $bindAll({
        [ComponentIds.ErrorText]: {
          text: () => $props.text || '',
        },
      });
    },
    exports: {
      getTextSelector: () => {
        return $w(ComponentIds.ErrorText);
      },
    },
  };
});

import {RootStore} from '../../stores/RootStore';
import {isUserInputValid} from '../user-input/isUserInputValid';

export const publishCustomizeProductEvent = (rootStore: RootStore) => {
  const state = rootStore.$state;

  if (isUserInputValid(rootStore)) {
    return rootStore.wixCodeApi.window.trackEvent(
      'CustomizeProduct' as any,
      {
        ...state.trackParams,
        variantId: state.selectedVariant?.id,
        optionsSelectionsIds: state.selectionIds,
        customTextFields: state.product.customTextFields.map((f, index) => state.textInput[index] || ''),
      } as any,
    );
  }
};

import {RootStore} from '../../../stores/RootStore';
import {RootWidgetComponentIds} from '../../../config/constants';

export const bindPlaceholdersProps = (rootStore: RootStore) => {
  const {$bindAll, flowAPI} = rootStore.controllerParams;

  $bindAll({
    [RootWidgetComponentIds.BottomPlaceholder]: {
      deleted: () => !flowAPI.environment.isViewer,
    },
    [RootWidgetComponentIds.DetailsPlaceholder]: {
      deleted: () => !flowAPI.environment.isViewer,
    },
  });
};

import {RootWidgetComponentIds} from '../../../config/constants';
import {RootStore} from '../../../stores/RootStore';
import {getProductTitle} from '../../product-service/getProductName';

export const bindTitleProps = (rootStore: RootStore) => {
  const {$bind} = rootStore.controllerParams;
  return $bind(RootWidgetComponentIds.ProductTitle, {
    text: () => getProductTitle(rootStore.$state) ?? '',
  });
};

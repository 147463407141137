import {action} from 'mobx';
import {HandleOptionChangeFn} from './types';
import {getProductVariantBySelectionIds} from '@wix/wixstores-client-core/dist/es/src/productVariantCalculator/ProductVariantCalculator';
import {mapUserInputSelectionMapToChoices} from '../../mappers/mapUserInputSelectionMapToChoices';
import {publishCustomizeProductEvent} from '../product-service/publishCustomizeProductEvent';
import {GqlProduct} from '../../../../types/type';
import {SPECS} from '../../../../specs';
import {IOptionSelectionVariant} from '@wix/wixstores-client-core/dist/src/types/product';

export const handleOptionChange: HandleOptionChangeFn = (rootStore) => {
  const resetQuantityInput = () => (rootStore.$state.quantityInput = 1);
  const isRemoveModifiersSelectionIdsFromUserSelections = rootStore.isExperimentEnabled(
    SPECS.RemoveModifiersFromUserInput,
  );

  return action(({data: {selectionId, optionId}}) => {
    rootStore.$state.selectionInput[optionId] = selectionId;
    rootStore.$state.selectedVariant = getProductVariantBySelectionIds({
      product: rootStore.$state.product as GqlProduct,
      variantSelectionIds: rootStore.$state.selectionIds,
      isRemoveModifiersSelectionIdsFromUserSelections,
    }) as IOptionSelectionVariant;

    resetQuantityInput();

    publishCustomizeProductEvent(rootStore);

    if (rootStore.wixCodeService.onChoiceSelectedCallback) {
      rootStore.wixCodeService.onChoiceSelectedCallback(mapUserInputSelectionMapToChoices(rootStore));
    }
  });
};

import model from './model';
import {SkuWidgetComponentIds as ComponentIds} from './config/constants';
import {initLocaleKeys} from '../../services/platform-service/initLocaleKeys';

export default model.createController((controllerParams) => {
  const {$bindAll, $props, flowAPI} = controllerParams;
  const t = initLocaleKeys(flowAPI.translations.i18n);

  return {
    pageReady: () => {
      $bindAll({
        [ComponentIds.Value]: {
          text: () => $props.sku || '',
          accessibility: {
            ariaAttributes: {
              hidden: () => true,
            },
            screenReader: {
              prefix: () =>
                t.productPage.sr.skuWidget.value.label({
                  skuCode: $props.sku,
                }),
            },
          },
        },
        [ComponentIds.Label]: {
          text: () => {
            return $props.label ? $props.label : t.SKU_LABEL();
          },
          accessibility: {
            ariaAttributes: {
              hidden: () => true,
            },
          },
        },
      });
    },
    exports: {},
  };
});

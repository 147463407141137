import {RootWidgetComponentIds} from '../../../config/constants';
import {RootStore} from '../../../stores/RootStore';

export const bindSkuWidgetProps = (rootStore: RootStore) => {
  const {$bind} = rootStore.controllerParams;
  return $bind(RootWidgetComponentIds.Sku, {
    sku: () => rootStore.$state.actualSku ?? '',
    deleted: () => !rootStore.$state.actualSku,
  });
};

import {IAdditionalInfo} from '../../../../types/type';
import {RootStoreState} from '../../stores/RootStoreState';
import {isRicoRichContent} from '../../tests/utils/RichContentViewerUtils';

export const getInfoSections = (state: RootStoreState): IAdditionalInfo[] => {
  return state.product.additionalInfo.map((textField) => ({
    _id: textField.id,
    title: textField.title,
    description: textField.description,
    isRico: isRicoRichContent(textField.description),
  }));
};

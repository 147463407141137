import {makeAutoObservable} from 'mobx';
import {RootWidgetComponentIds} from '../../../config/constants';
import {RootStore} from '../../../stores/RootStore';
import {getBreadcrumbsItems} from '../../getBreadcrumbsItems';

export const bindBreadcrumbsProps = (rootStore: RootStore) => {
  const {$bind} = rootStore.controllerParams;
  try {
    const breadcrumbsStore = makeAutoObservable({
      get items() {
        return getBreadcrumbsItems(rootStore);
      },
    });
    return $bind(RootWidgetComponentIds.Breadcrumbs, {
      items: () => breadcrumbsStore.items,
      deleted: () => breadcrumbsStore.items.length === 0,
    });
  } catch (_e) {
    //
  }
};

import {RootStore} from '../../stores/RootStore';

export const publishPageLoadedTrackEvent = (rootStore: RootStore): void => {
  const state = rootStore.$state;

  return rootStore.wixCodeApi.window.trackEvent(
    'productPageLoaded' as any,
    {
      productId: state.product.id,
      name: state.product.name,
      currency: state.product.currency,
      price: state.product.price,
      sku: state.product.sku,
    } as any,
  );
};

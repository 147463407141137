import {SPECS} from '../../../specs';
import {RootStore} from '../stores/RootStore';

export const mapUserInputSelectionMapToChoices = (rootStore: RootStore): Record<string, string> => {
  if (rootStore.isExperimentEnabled(SPECS.AddOptionsToCartWithKeyInsteadOfTranslation)) {
    return Object.entries(rootStore.$state.selectionInput).reduce((acc, [optionId, selectionId]) => {
      const selection = rootStore.$state.product.options.find((o) => o.id === optionId);
      const value = selection!.selections.find((_) => _.id === selectionId)!.key as string;
      return {...acc, [selection!.key as string]: value};
    }, {});
  } else {
    return Object.entries(rootStore.$state.selectionInput).reduce((acc, [optionId, selectionId]) => {
      const selection = rootStore.$state.product.options.find((o) => o.id === optionId);
      const value = selection!.selections.find((_) => _.id === selectionId)!.description;
      return {...acc, [selection!.title]: value};
    }, {});
  }
};

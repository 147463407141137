import {RootStore} from '../../../stores/RootStore';

interface GetSubscriptionFrequencyTranslationKeyParams {
  frequency: string;
}

const getSubscriptionFrequencyTranslationKey = ({frequency}: GetSubscriptionFrequencyTranslationKeyParams): string => {
  const keysFrequency = `${frequency?.toLowerCase()}s`;
  return `productPage.planPrice.${keysFrequency}.label`;
};

export const getFrequencyTitle = (rootStore: RootStore) => {
  const $state = rootStore.$state;
  const {t} = rootStore.controllerParams.flowAPI.translations;
  return $state.interval && $state.frequency
    ? t(getSubscriptionFrequencyTranslationKey({frequency: $state.frequency}), {
        interval: $state.interval,
      })
    : null;
};

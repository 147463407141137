import {makeAutoObservable} from 'mobx';
import {RootWidgetComponentIds} from '../../../config/constants';
import {RootStore} from '../../../stores/RootStore';
import {getInfoSections} from '../../product-service/getInfoSections';

export const bindInfoSectionWidgetProps = (rootStore: RootStore) => {
  const {$bind} = rootStore.controllerParams;
  const infoSectionsStore = makeAutoObservable({
    get items() {
      return getInfoSections(rootStore.$state);
    },
  });

  return $bind(RootWidgetComponentIds.Info, {
    sections: () => infoSectionsStore.items,
    deleted: () => infoSectionsStore.items.length === 0,
  });
};

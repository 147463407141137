import {getAppErrorHandler} from './getAppErrorHandler';
import {WidgetEventErrorHandler, WidgetEventHandler} from './types';
import {validateStateInputs} from '../user-input/validateStateInputs';
import {action} from 'mobx';
import {RootStore} from '../../stores/RootStore';

const focusOnInvalid = (rootStore: RootStore) => {
  const {userInputErrors} = rootStore.$state;
  const {optionsNewWidget, customTextFieldsWidget, quantityWidget, subscription} = rootStore.services.components;
  const invalidTextFieldIndex = userInputErrors.text.findIndex((t) => t);
  const invalidOptionIndex = userInputErrors.selection.findIndex((t) => t);
  const invalidSubscriptionIndex = userInputErrors.subscriptionPlan.findIndex((t) => t);
  if (invalidOptionIndex > -1) {
    optionsNewWidget.focus(invalidOptionIndex);
  } else if (invalidTextFieldIndex > -1) {
    customTextFieldsWidget.focus(invalidTextFieldIndex);
  } else if (invalidSubscriptionIndex > -1) {
    subscription.focus(invalidSubscriptionIndex);
  } else if (userInputErrors.quantity[0]) {
    quantityWidget.focus();
  }
};

export const getCtaButtonClickHandler = <K>(
  rootStore: RootStore,
  handleAction: WidgetEventHandler<K>,
  shouldValidateStateInputs = true,
  handleError?: WidgetEventErrorHandler<K>,
) => {
  const handleAppError = getAppErrorHandler(rootStore);
  return action(async (event) => {
    if (shouldValidateStateInputs) {
      rootStore.$state.isProductSubmitted = true;
      focusOnInvalid(rootStore);
    }
    try {
      if (shouldValidateStateInputs) {
        validateStateInputs(rootStore.$state, rootStore.controllerParams.flowAPI);
      }
      await handleAction(event);
    } catch (error) {
      await handleAppError(error);
      if (handleError) {
        await handleError(event, error);
      }
    }
  });
};

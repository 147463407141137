import {RootStore} from '../../../stores/RootStore';
import {QuantityCalculator} from '@wix/wixstores-client-core/dist/es/src/quantity-calculator/quantityCalculator';
import {MAX_QUANTITY_INPUT, MIN_QUANTITY_INPUT} from '../../../config/constants';
import {GqlProduct} from '../../../../../types/type';
import {SPECS} from '../../../../../specs';

export const getQuantityRange = (rootStore: RootStore): {max: number; min: number} => {
  const {$state} = rootStore;
  const isRemoveModifiersSelectionIdsFromUserSelections = rootStore.isExperimentEnabled(
    SPECS.RemoveModifiersFromUserInput,
  );
  const fixNegativeInventoryForPreOrder = rootStore.isExperimentEnabled(SPECS.FixNegativeInventoryError);
  const quantities = QuantityCalculator.getQuantitiesRange($state.product as GqlProduct, $state.optionSelections, {
    isRemoveModifiersSelectionIdsFromUserSelections,
    fixNegativeInventoryForPreOrder,
  });
  return {
    max: quantities.length > 0 ? quantities[quantities.length - 1] : MAX_QUANTITY_INPUT,
    min: quantities[0] ?? MIN_QUANTITY_INPUT,
  };
};

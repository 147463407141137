import {RootStore} from '../../../stores/RootStore';
import {ROOT_WIDGET_ID} from '../../../../../constants/app';
import applicationJson from '../../../../../../.application.json';

export const setReviewsProps = (rootStore: RootStore): void => {
  const {reviewsSummarySlot, reviewsSectionSlot} = rootStore.services.components;
  const env = {
    hostAppDefinitionId: applicationJson.appDefinitionId,
    hostWidgetId: ROOT_WIDGET_ID,
    hostAppName: applicationJson.appName,
    hostWidgetName: 'Product Page',
  };

  const device = rootStore.controllerParams.controllerConfig.wixCodeApi.window.formFactor;
  const layoutSettings = rootStore.controllerParams.$widget.props.layoutSettings;
  const alignment = getAlignmentForReviews(device, layoutSettings);

  reviewsSummarySlot.slot.alignment = alignment;
  reviewsSummarySlot.slot.resourceId = rootStore.$state.product.id;
  reviewsSummarySlot.slot.env = env;
  reviewsSectionSlot.slot.resourceId = rootStore.$state.product.id;
  reviewsSectionSlot.slot.env = env;

  void reviewsSummarySlot.expand();
  void reviewsSectionSlot.expand();
};

const getAlignmentForReviews = (device, layoutSettings) => {
  const layoutSettingsByDevice =
    device === 'Desktop' ? layoutSettings.desktop.alignment : layoutSettings.mobile.alignment;
  switch (layoutSettingsByDevice) {
    case 'left':
      return 'start';
    case 'right':
      return 'end';
    case 'center':
      return 'center';
    default:
      return 'start';
  }
};

import model from './model';
import {
  SubscriptionSelectableRepeaterItemComponentIds as RepeaterItemComponentIds,
  SubscriptionWidgetComponentIds as WidgetComponentIds,
  SubscriptionWidgetEvents as WidgetEvents,
} from './config/constants';
import {SubscriptionPlansView} from '@wix/wixstores-client-storefront-sdk/dist/es/src/services/ProductSubscriptionService/type';
import {initLocaleKeys} from '../../services/platform-service/initLocaleKeys';

export default model.createController((controllerParams) => {
  const {$widget, $bindAll, $props, $w, flowAPI} = controllerParams;
  const t = initLocaleKeys(flowAPI.translations.i18n);
  const reorderOptions = (options) => {
    const shouldShowOneTimePurchaseOptionFirst = $props.shouldShowOneTimePurchaseOptionFirst === 'first';
    const oneTimeOrder = options.filter((option: SubscriptionPlansView) => !option.frequency);
    const restOfOrders = options.filter((option: SubscriptionPlansView) => option.frequency);

    return shouldShowOneTimePurchaseOptionFirst ? oneTimeOrder.concat(restOfOrders) : restOfOrders.concat(oneTimeOrder);
  };

  const focusOnItem = (index: number) => {
    const itemId = $w(WidgetComponentIds.SelectableRepeater).data[index]._id;
    $w(WidgetComponentIds.SelectableRepeater).forItems([itemId], ($item) => {
      $item(RepeaterItemComponentIds.SelectableContainer1).focus();
    });
  };

  return {
    pageReady: () => {
      $bindAll({
        [WidgetComponentIds.Repeater]: {
          deleted: () => true, // TODO:Zeev: switching to SelectableRepeater
        },
        [WidgetComponentIds.Label]: {
          deleted: () => !$props.shouldShowTitle,
          text: () =>
            $props.titleText === 'Price Options' ? t.PRODUCT_PAGE_PURCHASE_OPTIONS_TITLE() : $props.titleText,
          onClick: () => focusOnItem(0),
        },
        [WidgetComponentIds.SelectableRepeater]: {
          // selectable repeater docs: https://docs.google.com/document/d/1GDO1cUv_wKMXVR9TQXJLA--YuJWno4q_--Ze25GIdQ4/edit
          // selectedIndex: () => 1,
          data: () => {
            $w(WidgetComponentIds.SelectableRepeater).data = [];
            const optionsInCorrectOrder = reorderOptions($props.options);
            return optionsInCorrectOrder.map((option: SubscriptionPlansView) => ({
              _id: option.id,
              ...option,
            }));
          },
          accessibility: {
            ariaAttributes: {
              labelledBy: () => $w(WidgetComponentIds.Label),
              describedBy: () => $w(WidgetComponentIds.ErrorMessage).getTextSelector(),
            },
          } as any,
          item: (itemData: SubscriptionPlansView, _$item, _index) => {
            return {
              [RepeaterItemComponentIds.Title]: {
                text: () => itemData.name ?? '',
              },
              [RepeaterItemComponentIds.Tagline]: {
                text: () => itemData.tagline ?? '',
                deleted: () => !itemData.tagline,
              },
              [RepeaterItemComponentIds.Price]: {
                text: () => itemData.formattedPrice ?? '',
              },
              [RepeaterItemComponentIds.Frequency]: {
                text: () => itemData.frequency ?? '',
                deleted: () => !itemData.frequency,
              },
              [RepeaterItemComponentIds.SelectableContainer1]: {
                inputLabel: () => itemData.name,
              } as any,
            };
          },
          // @ts-expect-error missing type
          onItemSelected: ($item, itemData) => {
            $widget.fireEvent(WidgetEvents.Change, {selectionId: itemData.id});
          },
        },
        [WidgetComponentIds.ErrorMessage]: {
          deleted: () => !$props.isError,
          text: () => ($props.isError ? $props.errorMessage : ''),
        },
      });
    },
    exports: {
      focus: (index: number) => {
        focusOnItem(index);
      },
    },
  };
});

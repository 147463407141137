import {RootStore} from '../../stores/RootStore';

export const isUserInputValid = (rootStore: RootStore): boolean => {
  const errors = rootStore.$state.userInputErrors;
  for (const property in errors) {
    if (errors.hasOwnProperty(property)) {
      if (errors[property].some((value) => value)) {
        return false;
      }
    }
  }
  return true;
};

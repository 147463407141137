import model from './model';
import {DropdownWidgetEvents as Events, DropdownComponentIds as ComponentIds} from './config/constants';
import {makeAutoObservable} from 'mobx';
import {initLocaleKeys} from '../../services/platform-service/initLocaleKeys';
import {ChoiceItem} from '../optionsNewWidget/config/types';
import {SPECS} from '../../specs';

export default model.createController(({$widget, $bindAll, $props, $w, flowAPI}) => {
  const t = initLocaleKeys(flowAPI.translations.i18n);
  const withNonControlledDropdown = flowAPI.experiments.enabled(SPECS.NonControlledDropdown);

  const $state = makeAutoObservable({
    get selectedIndex() {
      const index = $state.selections.findIndex((choice) => $props.selectionIds.includes(choice.selectionId));
      return index > -1 ? index : undefined;
    },
    get selectedChoice() {
      return $state.selectedIndex !== undefined && $state.selections[$state.selectedIndex];
    },
    get selections(): ChoiceItem[] {
      return $props.selections;
    },
  });
  return {
    pageReady: () => {
      $bindAll({
        [ComponentIds.TopContainer]: {
          hidden: () => $state.selections.length === 0,
        },
        [ComponentIds.Label]: {
          deleted: () => !$props.shouldShowTitle,
          text: () => ($state.selectedChoice ? `${$props.label}: ${$state.selectedChoice.description}` : $props.label),
          onClick: () => $w(ComponentIds.Dropdown).focus(),
        },
        [ComponentIds.Dropdown]: {
          onChange: (evt: $w.Event) => {
            const chosenValue = evt.target.value && parseInt(evt.target.value, 10);
            $widget.fireEvent(Events.Change, chosenValue);
          },
          options: () =>
            $state.selections?.map((choice) => ({
              label: !choice.isDisabled
                ? choice.description
                : t.productPage.productOptionsWidget.outOfStock.label({choiceName: choice.description}),
              value: choice._id,
            })),
          ...(withNonControlledDropdown
            ? {value: () => $props.selections?.[$state.selectedIndex!]?._id as string}
            : {selectedIndex: () => $state.selectedIndex as number}),
          required: () => $props.isError,
          placeholder: () => t.SELECT_PLACE_HOLDER(),
          accessibility: {
            ariaAttributes: {
              labelledBy: () => $w(ComponentIds.Label),
              describedBy: () => $w(ComponentIds.ErrorMessage).getTextSelector(),
            },
          },
        },
        [ComponentIds.ErrorMessage]: {
          deleted: () => !$props.isError,
          text: () => ($props.isError ? $props.errorMessage : ''),
        },
      });
    },
    exports: {
      focus: () => {
        $w(ComponentIds.Dropdown).focus();
      },
    },
  };
});

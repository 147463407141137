import {SeoBreadCrumbs, SeoProductBuilder} from '@wix/wixstores-client-core/dist/es/src/builders/SeoItemData.builder';
import {GqlProduct, IProduct} from '../../../../types/type';
import {isVideo} from '@wix/wixstores-client-core/dist/src/media/mediaService';
import {RootStore} from '../../stores/RootStore';
import {getIsViewer} from '../platform-service/getIsViewer';
import {SPECS} from '../../../../specs';

type VideoSeoData = {
  videoThumbnailUrl: string;
  videoDescription: string;
  videoContentUrl: string;
}[];

const getVideoSeoData = (gqlProduct: IProduct): VideoSeoData => {
  return gqlProduct.media.filter(isVideo).map((media) => {
    return {
      videoThumbnailUrl: media.thumbnailFullUrl,
      videoDescription: media.altText as string,
      videoName: gqlProduct.name,
      videoContentUrl: `https://video.wixstatic.com/${media.videoFiles[0].url}`,
    };
  });
};

const getSeoData = (gqlProduct: IProduct) => {
  return JSON.parse(gqlProduct.seoJson || '{}');
};

const getSeoBreadcrumbs = async (product: IProduct, rootStore: RootStore): Promise<SeoBreadCrumbs[]> => {
  const homePage = rootStore.$state.siteStructure.pages.find((page) => page.isHomePage);
  const haveHomePage = !!homePage;
  const positionBuffer = haveHomePage ? 2 : 1;

  const breadcrumbs: SeoBreadCrumbs[] = await Promise.all(
    (product.breadcrumbs || []).map(async (breadcrumb, index) => {
      return {
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        position: index + positionBuffer,
        name: breadcrumb.name,
        item: await rootStore.ecomPublicApi.product.getAbsoluteProductUrl({slug: breadcrumb.slug}),
      };
    }),
  );

  const seoBreadcrumbs = [
    ...(breadcrumbs || []),
    {
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      position: breadcrumbs ? breadcrumbs.length + positionBuffer : positionBuffer,
      name: product.name,
      item: rootStore.$state.absoluteProductUrl,
    },
  ];

  if (haveHomePage) {
    seoBreadcrumbs.unshift({
      position: 1,
      name: homePage.name || '',
      item: rootStore.controllerParams.controllerConfig.wixCodeApi.location.baseUrl + homePage.url,
    });
  }

  return seoBreadcrumbs;
};

export const setSeoTags = async (rootStore: RootStore): Promise<void> => {
  if (!getIsViewer(rootStore)) {
    return;
  }

  const shouldSendNewV3SeoData = rootStore.isExperimentEnabled(SPECS.SendSubcategoriesSeoDataSF);

  const seoData = getSeoData(rootStore.$state.product);
  const pageUrl = rootStore.$state.absoluteProductUrl;
  const productWithUrl = {...rootStore.$state.product, pageUrl};
  const productWithBase = {productPageBaseUrl: pageUrl};
  const productSeo = new SeoProductBuilder(
    productWithUrl as GqlProduct,
    productWithBase,
    rootStore.$state.shouldUseRicoDescription && rootStore.isExperimentEnabled(SPECS.EnableRicoForSeoTags),
    shouldSendNewV3SeoData,
  ).get();
  const videoData = getVideoSeoData(rootStore.$state.product);
  const legacySeoData = {
    title: rootStore.$state.product.seoTitle,
    description: rootStore.$state.product.seoDescription,
  };

  productSeo.breadcrumbs = shouldSendNewV3SeoData ? await getSeoBreadcrumbs(productWithUrl, rootStore) : undefined;

  const itemData = {
    product: productSeo,
    videoData,
    legacySeoData,
  };
  // TODO:Zeev: temp fix for race condition between SEO and react only in sled: https://wix.slack.com/archives/C03C54CDP4Z/p1661082149938469?thread_ts=1660202011.066219&cid=C03C54CDP4Z
  try {
    return rootStore.controllerParams.controllerConfig.wixCodeApi.seo.renderSEOTags({
      itemType: 'STORES_PRODUCT',
      seoData,
      itemData,
    });
  } catch (e) {
    /* istanbul ignore next reason: temp fix */
    // eslint-disable-next-line no-console
    console.log('Error rendering seo tags', e);
    /* istanbul ignore next reason: temp fix */
    return;
  }
};

import model from './model';
import {PriceAndDiscountMSBStates, PriceWidgetComponentIds as ComponentIds} from './config/constants';
import {initLocaleKeys} from '../../services/platform-service/initLocaleKeys';
import {autorun, when, toJS} from 'mobx';
import type {BannerOOIPublicAPI} from '@wix/payment-methods-banner-ooi/';
import {IPaymentMethodsBannerProps} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/wixcode/payments/getPaymentMethodsBannerProps';
import {isSpecialLanguage} from '../../services/platform-service/getIsSpecialLanguage';
import {SPECS} from '../../specs';

export default model.createController((controllerParams) => {
  const {$w, flowAPI, $bindAll, $props} = controllerParams;
  const t = initLocaleKeys(flowAPI.translations.i18n);
  const toJSOrUndefined = (value) => (value ? toJS(value) : undefined);

  const initCashierBanner = (cashierBannerProps?: IPaymentMethodsBannerProps) => {
    const cashierBanner = $w(ComponentIds.CashierBanner);
    const cashierBannerWidget: BannerOOIPublicAPI = cashierBanner as any;

    if (cashierBannerProps) {
      cashierBannerWidget?.configure?.({
        appDefId: cashierBannerProps.meta!.appDefId,
        currency: cashierBannerProps.currency!,
        price: Number(cashierBannerProps.amount),
        productId: cashierBannerProps.meta!.productId,
        ...(flowAPI.experiments.enabled(SPECS.PPOBAddDelayedCapturePropToPaymentMethodsBanner)
          ? {delayedCapture: cashierBannerProps.delayedCapture ?? false}
          : {}),
        onReady: (result) => {
          if (result.paymentMethods.length === 0) {
            void cashierBanner.collapse();
          } else {
            void $w(ComponentIds.CashierBanner).expand();
          }
        },
      });
    } else {
      return cashierBanner.collapse();
    }
  };

  return {
    pageReady: () => {
      autorun(() => {
        void $w(ComponentIds.PriceAndDiscountMSB).changeState(
          isSpecialLanguage(flowAPI.environment.language)
            ? PriceAndDiscountMSBStates.SpecialLanguage
            : PriceAndDiscountMSBStates.DefaultLanguage,
        );
      });

      const fromTextProps = {
        deleted: () => !$props.showPriceRange,
        text: () => t.productPage.price.from.label(),
      };

      const actualPriceProps = {
        text: () => $props.formattedActualPrice ?? '',
        accessibility: {
          screenReader: {
            prefix: () =>
              $props.showDiscount
                ? t.productPage.sr.pricingWidget.salePrice.label()
                : t.productPage.sr.pricingWidget.regularPrice.label(),
          },
        },
      };

      const originalPriceProps = {
        text: () => $props.formattedOriginalPrice ?? '',
        deleted: () => !$props.showDiscount,
        accessibility: {
          screenReader: {
            prefix: () => ($props.showDiscount ? t.productPage.sr.pricingWidget.originalPrice.label() : ''),
          },
        },
      };

      const frequencyProps = {
        text: () => `/ ${$props.frequencyLabel}`,
        deleted: () => !$props.frequencyLabel,
      };

      $bindAll({
        [ComponentIds.TopContainer]: {
          deleted: () => !$props.formattedActualPrice,
        },
        [ComponentIds.FromDefaultLang]: fromTextProps,
        [ComponentIds.FromSpecialLang]: fromTextProps,
        [ComponentIds.ActualPriceDefaultLang]: actualPriceProps,
        [ComponentIds.ActualPriceSpecialLang]: actualPriceProps,
        [ComponentIds.OriginalPriceDefaultLang]: originalPriceProps,
        [ComponentIds.OriginalPriceSpecialLang]: originalPriceProps,
        [ComponentIds.FrequencyDefaultLang]: frequencyProps,
        [ComponentIds.FrequencySpecialLang]: frequencyProps,
        [ComponentIds.DiscountTitle]: {
          deleted: () => !($props.showDiscountTitle && $props.discountTitle),
          text: () => $props.discountTitle ?? '',
        },
        [ComponentIds.PricePerUnitBox]: {
          deleted: () => !$props.showPricePerUnit,
        },
        [ComponentIds.PricePerUnit]: {
          text: () =>
            t.PRODUCT_PAGE_BASE_UNIT_PRICE({
              basePrice: $props.formattedPricePerUnit ?? '',
              units: $props.formattedUnitAndQuantity ?? '',
            }),
          accessibility: {
            ariaAttributes: {
              hidden: () => true,
            },
            screenReader: {
              prefix: () =>
                $props.showPricePerUnit
                  ? t.SR_PRODUCT_PAGE_BASE_UNIT_PRICE({
                      basePrice: $props.formattedPricePerUnit,
                      units: $props.srFormattedUnitAndQuantity,
                    })
                  : '',
            },
          },
        },
        [ComponentIds.BreakdownBox]: {
          deleted: () => !$props.showShippingDisclaimer && !$props.showTaxDisclaimer,
        },
        [ComponentIds.TaxDisclaimer]: {
          text: () => ($props.showTaxDisclaimer ? $props.taxDisclaimerText ?? '' : ''),
          deleted: () => !$props.showTaxDisclaimer,
        },
        [ComponentIds.ShippingDisclaimer]: {
          richText: () => {
            if ($props.showShippingDisclaimerModal) {
              return `<p><a tabindex="0"><u>${$props.shippingDisclaimerText}</u></a></p>`;
            }
            return `<p>${$props.shippingDisclaimerText}</p>`;
          },
          deleted: () => !$props.showShippingDisclaimer,
        },
        [ComponentIds.DisclaimerSeparator]: {
          deleted: () => !($props.showShippingDisclaimer && $props.showTaxDisclaimer),
        },
      });
      when(
        () => $props.showShippingDisclaimerModal,
        () => {
          $w(ComponentIds.ShippingDisclaimer).onClick(() => {
            flowAPI.openModal('ShippingDisclaimer', {width: 500, height: 500});
          });
        },
      );

      void $w(ComponentIds.CashierBanner).collapse(); // TODO:Zeev: WIP: testing if running this outside of autorun will fix the jumping SSR issue

      autorun(() => initCashierBanner(toJSOrUndefined($props.cashierBannerProps)));
    },
    exports: {},
  };
});

/* eslint-disable no-console */
import {RootStore} from '../../stores/RootStore';
import {bindPriceWidgetProps} from './widget-props/bindPriceWidgetProps';
import {bindQuantityWidgetProps} from './widget-props/bindQuantityWidgetProps';
import {bindSocialShareWidgetProps} from './widget-props/bindSocialShareWidgetProps';
import {bindSubscriptionWidgetProps} from './widget-props/bindSubscriptionWidgetProps';
import {bindGalleryProps} from './widget-props/bindGalleryProps';
import {bindDescriptionProps} from './widget-props/bindDescriptionProps';
import {bindCtaButtonsProps} from './widget-props/bindCtaButtonsProps';
import {autorun} from 'mobx';
import {setReviewsProps} from './widget-props/setReviewsProps';
import {bindCustomTextFieldWidgetProps} from './widget-props/bindCustomTextFieldWidgetProps';
import {bindSkuWidgetProps} from './widget-props/bindSkuWidgetProps';
import {bindInfoSectionWidgetProps} from './widget-props/bindInfoSectionWidgetProps';
import {bindBreadcrumbsProps} from './widget-props/bindBreadcrumbsProps';
import {bindStockIndicatorWidgetProps} from './widget-props/bindStockIndicatorWidgetProps';
import {bindNavigationWidgetProps} from './widget-props/bindNavigationWidgetProps';
import {bindTitleProps} from './widget-props/bindTitleProps';
import {bindOptionsRepeaterWidgetProps} from './widget-props/bindOptionsRepeaterWidgetProps';
import {bindPlaceholdersProps} from './widget-props/bindPlaceholdersProps';
import {setProductPageSlotsProps} from './widget-props/setProductPageSlotsProps';

export const bindWidgetsProps = (rootStore: RootStore): void => {
  bindTitleProps(rootStore);
  bindDescriptionProps(rootStore);
  bindOptionsRepeaterWidgetProps(rootStore);
  bindGalleryProps(rootStore);
  bindCustomTextFieldWidgetProps(rootStore);
  bindSkuWidgetProps(rootStore);
  bindInfoSectionWidgetProps(rootStore);
  bindBreadcrumbsProps(rootStore);
  bindStockIndicatorWidgetProps(rootStore);
  bindNavigationWidgetProps(rootStore);
  bindCtaButtonsProps(rootStore);
  bindQuantityWidgetProps(rootStore);
  bindSocialShareWidgetProps(rootStore);
  bindPriceWidgetProps(rootStore);
  bindSubscriptionWidgetProps(rootStore);
  bindPlaceholdersProps(rootStore);
  autorun(() => setReviewsProps(rootStore));
  autorun(() => setProductPageSlotsProps(rootStore));
};

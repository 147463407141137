import {RootStore} from '../../../stores/RootStore';
import {StockIndicatorStates} from '../../../../stockIndicatorWidget/config/constants';
import {autorun, makeAutoObservable, runInAction} from 'mobx';
import {RootWidgetComponentIds} from '../../../config/constants';

export const bindStockIndicatorWidgetProps = (rootStore: RootStore): void => {
  const {$w, $bind} = rootStore.controllerParams;

  const indicatorStore = makeAutoObservable({
    get isBelowThreshold() {
      return (
        rootStore.$state.inStock &&
        rootStore.$state.maxQuantity > 0 &&
        rootStore.$state.maxQuantity < $w(RootWidgetComponentIds.StockIndicator).settings.threshold
      );
    },
    get activeState() {
      if (indicatorStore.isBelowThreshold) {
        return StockIndicatorStates.StockIndicator;
      } else if (rootStore.$state.showNotifyMe) {
        return StockIndicatorStates.OutOfStock;
      }
      return undefined;
    },
    get isHidden() {
      return !indicatorStore.activeState;
    },
  });
  $bind(RootWidgetComponentIds.StockIndicator, {
    deleted: () => indicatorStore.isHidden,
  });
  autorun(() => {
    const {activeState} = indicatorStore;
    const {maxQuantity} = rootStore.$state;
    runInAction(() => {
      $w(RootWidgetComponentIds.StockIndicator).settings.activeState = activeState ?? null;
      $w(RootWidgetComponentIds.StockIndicator).data.amountInStock = maxQuantity;
    });
  });
};

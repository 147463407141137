import model from './model';
import {
  OptionsWidgetEvents as Events,
  optionsNewWidgetComponentIds as ComponentIds,
  optionsNewWidgetItemComponentIds as ItemComponentIds,
  OptionsStates,
} from './config/constants';
import {ProductOptionType as OptionType} from '@wix/wixstores-graphql-schema';
import {clickOnProductOptionSf} from '@wix/bi-logger-ec-sf/v2';
import {makeAutoObservable} from 'mobx';
import {IProductExtendedOption} from '../../types/type';
import {ChoiceItem} from './config/types';

type ItemData = IProductExtendedOption & {_id: string};

export default model.createController((controllerParams) => {
  const {$bindAll, flowAPI, $props, $widget, $w} = controllerParams;
  const mapChoice = (choice): ChoiceItem => ({
    _id: choice.id.toString(),
    selectionId: choice.id,
    value: choice.value,
    description: choice.description,
    isDisabled: choice.isDisabled,
  });

  return {
    pageReady: () => {
      $bindAll({
        [ComponentIds.Repeater]: {
          data: () =>
            $props.productOptions.map((option, index) => ({
              _id: option.id,
              ...option,
            })),
          item: ({_id}: ItemData) => {
            const $optionState = makeAutoObservable({
              get optionData() {
                return $props.productOptions?.find((c) => c.id === _id);
              },
              get isExist() {
                return this.optionData !== undefined;
              },
              get type() {
                return this.optionData?.optionType;
              },
              get optionId(): string | undefined {
                return this.optionData?.id;
              },
              get title() {
                return this.optionData?.title ?? '';
              },
              get visibleChoices() {
                return this.optionData?.selections?.filter((choice) => choice.isVisible).map(mapChoice) || [];
              },
              get isError() {
                return !!this.optionData?.validation?.isError;
              },
              get errorMessage() {
                return this.optionData?.validation?.errorMessage ?? '';
              },
            });

            const handleOptionChange = (selectionId) => {
              void flowAPI.bi!.report(
                clickOnProductOptionSf({
                  isBlocks: true,
                }),
              );
              $widget.fireEvent(Events.Change, {selectionId, optionId: $optionState.optionId});
            };

            return {
              [ItemComponentIds.ColorOption]: {
                onChange: (event: $w.Event & {data: any}) => {
                  handleOptionChange(event.data);
                },
                selectionIds: () => $props.selectionIds,
                label: () => $optionState.title,
                shouldShowTitle: () => $props.settings?.shouldShowTitle,
                selections: () => ($optionState.type === OptionType.COLOR ? $optionState.visibleChoices : []),
                isError: () => $optionState.isError,
                errorMessage: () => $optionState.errorMessage,
              },
              [ItemComponentIds.DropdownOption]: {
                onChange: (event: $w.Event & {data: any}) => {
                  handleOptionChange(event.data);
                },
                shouldShowTitle: () => $props.settings?.shouldShowTitle,
                selectionIds: () => $props.selectionIds,
                label: () => $optionState.title,
                selections: () => ($optionState.type === OptionType.DROP_DOWN ? $optionState.visibleChoices : []),
                isError: () => $optionState.isError,
                errorMessage: () => $optionState.errorMessage,
              },
              [ItemComponentIds.MultiStateBox]: {
                currentState: () =>
                  $optionState.type === OptionType.DROP_DOWN ? OptionsStates.DropdownState : OptionsStates.ColorState,
              },
            };
          },
        },
      });
    },
    exports: {
      focus: (index: number) => {
        const optionId = $w(ComponentIds.Repeater).data[index]?._id;
        $w(ComponentIds.Repeater).forItems([optionId], ($item, itemData: ItemData) => {
          switch (itemData?.optionType) {
            case OptionType.DROP_DOWN:
              $item(ItemComponentIds.DropdownOption).focus();
              break;
            case OptionType.COLOR:
              $item(ItemComponentIds.ColorOption).focus();
              break;
            default:
              break;
          }
        });
      },
    },
  };
});

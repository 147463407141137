import {makeAutoObservable} from 'mobx';
import {RootWidgetComponentIds} from '../../../config/constants';
import {RootStore} from '../../../stores/RootStore';
import {getMediaItems} from '../../product-service/media/getMediaItems';

export const bindGalleryProps = (rootStore: RootStore) => {
  const {$bind} = rootStore.controllerParams;

  const galleryStore = makeAutoObservable({
    get items() {
      return getMediaItems(rootStore.$state);
    },
    get isEmpty() {
      return galleryStore.items.length === 0;
    },
  });

  return $bind(RootWidgetComponentIds.Gallery, {
    items: () => galleryStore.items,
  });
};

import {RootStore} from '../../stores/RootStore';
import {ICustomTextField} from '../../../customTextFieldsWidget/config/types';
import {getTextFieldValidation} from '../validator-service/getTextFieldValidation';

export const getCustomTextFields = (rootStore: RootStore): ICustomTextField[] => {
  return rootStore.$state.product.customTextFields.map((textField, index) => ({
    id: `${rootStore.$state.product.id}-${index}`,
    title: textField.title,
    maxLength: textField.inputLimit,
    required: textField.isMandatory,
    value: rootStore.$state.userInput.text[index],
    validation: getTextFieldValidation(rootStore, index),
  }));
};

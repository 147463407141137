import {UserInputType} from '../../../../constants/stores';
import {RootStore} from '../../stores/RootStore';
import {ValidationResult} from '../../../../types/type';

export const getOptionValidation = (
  rootStore: RootStore,
  optionTitle: string,
  optionIndex: number,
): ValidationResult => {
  const {isProductSubmitted, userInputErrors} = rootStore.$state;
  const isError = isProductSubmitted && userInputErrors[UserInputType.Selection][optionIndex];
  return {isError, errorMessage: rootStore.t.productPage.productOptionsWidget.errorMessage.selectOption({optionTitle})};
};

import model from './model';
import {
  CustomTextFieldsWidgetComponentIds as WidgetComponentIds,
  CustomTextFieldsItemComponentIds as ItemComponentIds,
  CustomTextFieldsWidgetEvents as Events,
} from './config/constants';
import {initLocaleKeys} from '../../services/platform-service/initLocaleKeys';
import {makeAutoObservable} from 'mobx';
import {clickOnFreeText} from '@wix/bi-logger-ec-sf/v2';

export default model.createController((controllerParams) => {
  const {$w, $widget, $bindAll, $props, flowAPI} = controllerParams;
  const t = initLocaleKeys(flowAPI.translations.i18n);

  const reportBi = () => {
    void flowAPI.bi!.report(
      clickOnFreeText({
        isBlocks: true,
      }),
    );
  };

  const handleFreeTextBoxFocus = () => {
    reportBi();
  };

  const handleItemReady = ($item, itemData: {_id: string}) => {
    $item(ItemComponentIds.TextBox).accessibility.ariaAttributes.labelledBy = $item(ItemComponentIds.Label);
    $item(ItemComponentIds.TextBox).accessibility.ariaAttributes.describedBy = $item(
      ItemComponentIds.Error,
    ).getTextSelector();
  };

  return {
    pageReady: () => {
      $bindAll({
        [WidgetComponentIds.TopContainer]: {
          hidden: () => !$props.fields.length,
        },
        [WidgetComponentIds.Repeater]: {
          data: () => {
            return $props.fields.map((field) => ({_id: field.id}));
          },
          item: ({_id: itemId}, $bindItem, index) => {
            const $fieldState = makeAutoObservable({
              get value() {
                return $fieldState.data?.value ?? '';
              },
              get length() {
                return $fieldState.value.length;
              },
              get data() {
                if (index < $props.fields.length) {
                  return $props.fields[index];
                }
                return undefined;
              },
              get isRequired() {
                return !!$fieldState.data?.required;
              },
              get isError() {
                return !!$fieldState.data?.validation?.isError;
              },
              get title() {
                return $fieldState.data?.title ?? '';
              },
              get maxLength(): number | undefined {
                return $fieldState.data?.maxLength ?? 99999;
              },
            });
            return {
              [ItemComponentIds.Label]: {
                text: () =>
                  $fieldState.isRequired
                    ? $fieldState.title
                    : t.productPage.customTextWidget.optional.label({customTextTitle: $fieldState.title}),
                onClick: () => {
                  handleFreeTextBoxFocus();
                  $w(WidgetComponentIds.Repeater).forItems([itemId], ($item) => {
                    $item(ItemComponentIds.TextBox).focus();
                  });
                },
                accessibility: {
                  screenReader: {
                    suffix: () =>
                      t.productPage.sr.customTextWidget.maxCharacters({
                        numCharacters: $fieldState.data?.maxLength,
                      }),
                  },
                },
              },
              [ItemComponentIds.TextBox]: {
                onFocus: handleFreeTextBoxFocus,
                maxLength: () => $fieldState.maxLength as number,
                required: () => ($fieldState.isRequired && $fieldState.isError) || false,
                onInput: (evt: $w.Event) => {
                  $widget.fireEvent(Events.Change, {customTextField: evt.target.value, index});
                },
                value: () => $fieldState.value,
              },
              [ItemComponentIds.CharCounter]: {
                text: () => `${$fieldState.length} / ${$fieldState.maxLength}`,
              },
              [ItemComponentIds.Error]: {
                deleted: () => !$fieldState.isError,
                text: () => $fieldState.data?.validation?.errorMessage ?? '',
              },
            };
          },
        },
      });
      $w(WidgetComponentIds.Repeater).onItemReady(handleItemReady);
    },
    exports: {
      focus: (index: number) => {
        const itemId = $w(WidgetComponentIds.Repeater).data[index]._id;
        $w(WidgetComponentIds.Repeater).forItems([itemId], ($item) => {
          $item(ItemComponentIds.TextBox).focus();
        });
      },
    },
  };
});

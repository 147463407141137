import {RootStore} from '../../../stores/RootStore';
import {SPECS} from '../../../../../specs';

export const setProductPageSlotsProps = (rootStore: RootStore): void => {
  const productPageSlotsElements = [
    rootStore.services.components.productPageDetails1Slot,
    rootStore.services.components.productPageDetails2Slot,
    rootStore.services.components.productPageDetails3Slot,
    rootStore.services.components.productPageDetails4Slot,
    rootStore.services.components.productPageDetails5Slot,
    rootStore.services.components.productPageDetails6Slot,
    rootStore.services.components.productPageDetails7Slot,
    rootStore.services.components.productPageDetails8Slot,
    rootStore.services.components.productPageDetails9Slot,
    rootStore.services.components.productPageTopSlot,
    rootStore.services.components.productPageBottomSlot,
    rootStore.services.components.productPageMedia1Slot,
  ];

  const shouldRenderProductPageSlots = rootStore.isExperimentEnabled(SPECS.PRODUCT_PAGE_BLOCKS_SLOTS);
  productPageSlotsElements.forEach((slotElement) => {
    slotElement.slot.productId = rootStore.$state.product.id;
    if (!shouldRenderProductPageSlots) {
      slotElement.delete?.();
    }
  });
};

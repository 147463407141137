import {MAX_QUANTITY_INPUT, MIN_QUANTITY_INPUT} from '../../config/constants';

export const enforceQuantity = (quantity: number): number => {
  if (quantity > MAX_QUANTITY_INPUT) {
    return MAX_QUANTITY_INPUT;
  } else if (quantity < MIN_QUANTITY_INPUT) {
    return MIN_QUANTITY_INPUT;
  } else {
    return quantity;
  }
};

import {ComponentsService} from '../services/ComponentsService';
import {RootStore} from './RootStore';

export type RootServices = {
  components: ComponentsService;
};

export const createServices = (rootStore: RootStore): RootServices => {
  return {components: new ComponentsService(rootStore)};
};

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {RootWidgetComponentIds} from '../config/constants';
import {RootStore} from '../stores/RootStore';

export class ComponentsService {
  constructor(private readonly rootStore: RootStore) {
    //
  }

  public get title() {
    return this.rootStore.controllerParams.$w(RootWidgetComponentIds.ProductTitle);
  }

  public get collapsibleDescription() {
    return this.rootStore.controllerParams.$w(RootWidgetComponentIds.CollapsibleDescription);
  }

  public get skuWidget() {
    return this.rootStore.controllerParams.$w(RootWidgetComponentIds.Sku);
  }

  public get quantityWidget() {
    return this.rootStore.controllerParams.$w(RootWidgetComponentIds.Quantity);
  }

  public get infoSectionWidget() {
    return this.rootStore.controllerParams.$w(RootWidgetComponentIds.Info);
  }

  public get priceWidget() {
    return this.rootStore.controllerParams.$w(RootWidgetComponentIds.Price);
  }

  public get optionsNewWidget() {
    return this.rootStore.controllerParams.$w(RootWidgetComponentIds.OptionsNew);
  }

  public get ctaButtonsWidget() {
    return this.rootStore.controllerParams.$w(RootWidgetComponentIds.CtaButtons);
  }

  public get customTextFieldsWidget() {
    return this.rootStore.controllerParams.$w(RootWidgetComponentIds.CustomTextFields);
  }

  public get socialShare() {
    return this.rootStore.controllerParams.$w(RootWidgetComponentIds.SocialShare);
  }

  public get subscription() {
    return this.rootStore.controllerParams.$w(RootWidgetComponentIds.Subscription);
  }

  public get proGallery() {
    return this.rootStore.controllerParams.$w(RootWidgetComponentIds.Gallery);
  }

  public get navigation() {
    return this.rootStore.controllerParams.$w(RootWidgetComponentIds.Navigation);
  }

  public get breadcrumbs() {
    return this.rootStore.controllerParams.$w(RootWidgetComponentIds.Breadcrumbs);
  }

  public get stockIndicator() {
    return this.rootStore.controllerParams.$w(RootWidgetComponentIds.StockIndicator);
  }

  public get reviewsSummarySlot() {
    return this.rootStore.controllerParams.$w(RootWidgetComponentIds.ReviewsSummarySlot);
  }

  public get reviewsSectionSlot() {
    return this.rootStore.controllerParams.$w(RootWidgetComponentIds.ReviewsSectionSlot);
  }

  public get productPageTopSlot() {
    return this.rootStore.controllerParams.$w(RootWidgetComponentIds.ProductPageTopSlot);
  }

  public get productPageBottomSlot() {
    return this.rootStore.controllerParams.$w(RootWidgetComponentIds.ProductPageBottomSlot);
  }

  public get productPageDetails1Slot() {
    return this.rootStore.controllerParams.$w(RootWidgetComponentIds.ProductPageDetails1Slot);
  }

  public get productPageDetails2Slot() {
    return this.rootStore.controllerParams.$w(RootWidgetComponentIds.ProductPageDetails2Slot);
  }

  public get productPageDetails3Slot() {
    return this.rootStore.controllerParams.$w(RootWidgetComponentIds.ProductPageDetails3Slot);
  }

  public get productPageDetails4Slot() {
    return this.rootStore.controllerParams.$w(RootWidgetComponentIds.ProductPageDetails4Slot);
  }

  public get productPageDetails5Slot() {
    return this.rootStore.controllerParams.$w(RootWidgetComponentIds.ProductPageDetails5Slot);
  }

  public get productPageDetails6Slot() {
    return this.rootStore.controllerParams.$w(RootWidgetComponentIds.ProductPageDetails6Slot);
  }

  public get productPageDetails7Slot() {
    return this.rootStore.controllerParams.$w(RootWidgetComponentIds.ProductPageDetails7Slot);
  }

  public get productPageDetails8Slot() {
    return this.rootStore.controllerParams.$w(RootWidgetComponentIds.ProductPageDetails8Slot);
  }

  public get productPageDetails9Slot() {
    return this.rootStore.controllerParams.$w(RootWidgetComponentIds.ProductPageDetails9Slot);
  }

  public get productPageMedia1Slot() {
    return this.rootStore.controllerParams.$w(RootWidgetComponentIds.ProductPageMedia1Slot);
  }
}

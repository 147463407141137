import model from './model';
import {defaultSocialShareEnglishLabels, SocialShareWidgetComponentIds as ComponentIds} from './config/constants';
import {initLocaleKeys} from '../../services/platform-service/initLocaleKeys';
import {socialButtons} from '@wix/bi-logger-ec-sf/v2';

export default model.createController((controllerParams) => {
  const {$bindAll, $props, flowAPI, $w} = controllerParams;
  const t = initLocaleKeys(flowAPI.translations.i18n);

  const reportBi = () => {
    void flowAPI.bi!.report(
      socialButtons({
        isBlocks: true,
      }),
    );
  };
  const handleSocialShareClick = () => {
    reportBi();
  };

  const isDefaultEnglishLabel = (label) => defaultSocialShareEnglishLabels.filter((elem) => elem === label).length > 0;
  const getTranslationOrUserText = (label, translation) => {
    if (!label || isDefaultEnglishLabel(label)) {
      return translation;
    }

    return label;
  };

  return {
    pageReady: () => {
      $bindAll({
        [ComponentIds.Facebook]: {
          link: () => $props.facebookLink || '#',
          target: () => '_blank',
          onClick: handleSocialShareClick,
          label: () => getTranslationOrUserText($w(ComponentIds.Facebook).label, t.productPage.facebookButton.text()),
        },
        [ComponentIds.Pinterest]: {
          link: () => $props.pinterestLink || '#',
          target: () => '_blank',
          onClick: handleSocialShareClick,
          label: () => getTranslationOrUserText($w(ComponentIds.Pinterest).label, t.productPage.pinterestButton.text()),
        },
        [ComponentIds.Twitter]: {
          link: () => $props.twitterLink || '#',
          target: () => '_blank',
          onClick: handleSocialShareClick,
          label: () => getTranslationOrUserText($w(ComponentIds.Twitter).label, t.productPage.twitterButton.text()),
        },
        [ComponentIds.WhatsApp]: {
          link: () => $props.whatsappLink || '#',
          target: () => '_blank',
          onClick: handleSocialShareClick,
          label: () => getTranslationOrUserText($w(ComponentIds.WhatsApp).label, t.productPage.whatsappButton.text()),
        },
        [ComponentIds.TopContainer]: {
          accessibility: {
            role: () => 'navigation',
            ariaAttributes: {
              label: () => t.productPage.sr.shareButtonsWidget.container.name(),
            },
          },
        },
      });
    },
    exports: {},
  };
});

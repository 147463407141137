import model from './model';
import {RootStore} from './stores/RootStore';
import {waitUntilReadyFactory} from '../../utils/utils';
import {
  OnChoiceSelectedCallbackType,
  OnQuantityChangedCallbackType,
  OverrideCallback,
} from './services/rootWidgetApiService';

export default model.createController((controllerParams) => {
  let rootStore: RootStore;

  const {onReadyResolver, waitUntilReady} = waitUntilReadyFactory();

  const waitUntilAllIsReady = async () => {
    await waitUntilReady;
    return rootStore.waitForLocationChangedReady;
  };

  return {
    pageReady: () => {
      rootStore = new RootStore(controllerParams);
      return rootStore.init().then(onReadyResolver);
    },
    exports: {
      getProduct: async () => {
        await waitUntilAllIsReady();
        return rootStore.wixCodeService.getProductWixCode();
      },
      getCustomTextFieldsValues: async () => {
        // @deprecated
        // TODO:Zeev: we were asked by Docs to rename this to "getCustomText". I will keep it for now and remove after checking that no one is usong it
        await waitUntilAllIsReady();
        return rootStore.wixCodeService.getCustomText();
      },
      getCustomText: async () => {
        await waitUntilAllIsReady();
        return rootStore.wixCodeService.getCustomText();
      },
      setAddToCartLabel: async (text: string) => {
        await waitUntilAllIsReady();
        return rootStore.wixCodeService.setAddToCartLabel(text);
      },
      setBuyNowLabel: async (text: string) => {
        await waitUntilAllIsReady();
        return rootStore.wixCodeService.setBuyNowLabel(text);
      },
      onAddToCart: async (callback: OverrideCallback) => {
        await waitUntilAllIsReady();
        return rootStore.wixCodeService.addToCartOverride(callback);
      },
      onBuyNow: async (callback: OverrideCallback) => {
        await waitUntilAllIsReady();
        return rootStore.wixCodeService.onBuyNowOverride(callback);
      },
      onChoiceSelected: async (callback: OnChoiceSelectedCallbackType) => {
        await waitUntilAllIsReady();
        return rootStore.wixCodeService.onChoiceSelected(callback);
      },
      getSelectedVariantId: async () => {
        await waitUntilAllIsReady();
        return rootStore.wixCodeService.getSelectedVariantId();
      },
      getSelectedChoices: async () => {
        await waitUntilAllIsReady();
        return rootStore.wixCodeService.getSelectedChoices();
      },
      getQuantity: async () => {
        await waitUntilAllIsReady();
        return rootStore.wixCodeService.getQuantity();
      },
      setQuantity: async (value: number) => {
        await waitUntilAllIsReady();
        return rootStore.wixCodeService.setQuantity(value);
      },
      onQuantityChanged: async (callback: OnQuantityChangedCallbackType) => {
        await waitUntilAllIsReady();
        return rootStore.wixCodeService.onQuantityChanged(callback);
      },
    },
  };
});

import {IProductOptionsItem} from '@wix/wixstores-client-core/dist/es/src/types/product';
import {RootStore} from '../../../stores/RootStore';

export const getProductOptionsItems = (rootStore: RootStore): (IProductOptionsItem & {id: string})[] => {
  const selectionAvailability = rootStore.$state.selectionAvailability;
  return rootStore.$state.product.options.map((option) => {
    return {
      id: option.id,
      optionType: option.optionType,
      title: option.title,
      selections: option.selections.map((selection) => ({
        id: selection.id,
        value: selection.value as string,
        description: selection.description as string,
        linkedMediaItems: selection.linkedMediaItems as any[],
        key: selection.key as string,
        isVisible: selectionAvailability[option.id][selection.id].isVisible,
        isDisabled: !selectionAvailability[option.id][selection.id].isSelectable,
      })),
    };
  });
};

import {makeAutoObservable} from 'mobx';
import {RootWidgetComponentIds} from '../../../config/constants';
import {RootStore} from '../../../stores/RootStore';
import {getCustomTextFields} from '../../product-service/getCustomTextFields';

export const bindCustomTextFieldWidgetProps = (rootStore: RootStore) => {
  const {$bind} = rootStore.controllerParams;
  const textFieldsStore = makeAutoObservable({
    get items() {
      return getCustomTextFields(rootStore);
    },
  });
  return $bind(RootWidgetComponentIds.CustomTextFields, {
    fields: () => textFieldsStore.items,
    deleted: () => textFieldsStore.items.length === 0,
  });
};

import {IWixAPI} from '@wix/yoshi-flow-editor';
import {IViewerScriptExports} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/wixcode/createWixcodeExports';
import {origin} from '../../../constants/app';
import {getSlugFromPlatform} from '../services/product-service/getSlugFromPlatform';
import {setSeoTags} from '../services/product-service/setSeoTags';
import {handleInteractions} from '../services/widget/handleInteractions';
import {createInitialState} from './createInitialState';
import {bindWidgetsProps} from '../services/widget/bindWidgetsProps';
import {RootWidgetControllerParams} from '../config/types';
import {RootStoreState} from './RootStoreState';
import {createReportFedopsInteraction, ReportFedopsInteractionFn} from './createReportFedopsInteraction';
import {createServices, RootServices} from './createServices';
import {getEcomPublicApi, getStoresPublicApi} from './getPublicApis';
import {ILocaleKeys} from '../../../locale-keys/LocaleKeys';
import {initLocaleKeys} from '../../../services/platform-service/initLocaleKeys';
import {ExpressCheckoutStore} from './ExpressCheckoutStore';
import {EcomHttpClient} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/site-store/EcomHttpClient';
import {getEcomHttpClient} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/site-store/editor-flow/getEcomHttpClient';
import {publishRelatedProductsEvent} from '../services/product-service/publishRelatedProductsEvent';
import {RootWidgetApiService} from '../services/rootWidgetApiService';
import {waitUntilReadyFactory} from '../../../utils/utils';
import {publishPageLoadedTrackEvent} from '../services/product-service/publishPageLoadedTrackEvent';
import {publishTrackViewContentTrackEvent} from '../services/product-service/publishTrackViewContentTrackEvent';
import {SPECS} from '../../../specs';

export class RootStore {
  public $state!: RootStoreState;
  public origin = origin;
  public services: RootServices;
  public t: ILocaleKeys;
  public wixCodeApi: IWixAPI;
  public ecomPublicApi: IViewerScriptExports;
  public storesPublicApi?: IViewerScriptExports;
  public reportFedopsInteraction: ReportFedopsInteractionFn;
  public expressCheckoutStore: ExpressCheckoutStore;
  public ecomHttpClient: EcomHttpClient;
  public readonly breadcrumbsInitialItems: $w.Breadcrumbs.Item[];
  public wixCodeService: RootWidgetApiService;
  public waitForLocationChangedReady: Promise<void>;

  constructor(public readonly controllerParams: RootWidgetControllerParams) {
    this.wixCodeApi = this.controllerParams.controllerConfig.wixCodeApi;
    this.t = initLocaleKeys(this.controllerParams.flowAPI.translations.i18n);
    this.reportFedopsInteraction = createReportFedopsInteraction(this);
    this.services = createServices(this);
    this.ecomHttpClient = getEcomHttpClient({
      flowAPI: this.controllerParams.flowAPI as any,
      wixCodeApi: this.wixCodeApi as any,
    });
    try {
      this.breadcrumbsInitialItems = [...this.services.components.breadcrumbs.items];
    } catch (_e) {
      //
    }
    handleInteractions(this);
  }

  private async initProduct(slug?: string) {
    const {onReadyResolver, waitUntilReady} = waitUntilReadyFactory();

    this.waitForLocationChangedReady = waitUntilReady;
    const initialState = await createInitialState(this, slug ?? (getSlugFromPlatform(this) as string));
    if (initialState) {
      this.$state = initialState;
      this.wixCodeService = new RootWidgetApiService(this);
      bindWidgetsProps(this);
      setSeoTags(this);
      publishRelatedProductsEvent(this);
      publishPageLoadedTrackEvent(this);
      publishTrackViewContentTrackEvent(this);

      return onReadyResolver('');
    }
  }
  public async init(): Promise<void> {
    this.ecomPublicApi = await getEcomPublicApi(this);
    if (this.isExperimentEnabled(SPECS.AddToCartUseStoresViewerScript)) {
      this.storesPublicApi = await getStoresPublicApi(this);
    }
    await this.initProduct();
    this.wixCodeApi.location.onChange(() => void this.initProduct());
    this.controllerParams.$widget.onPropsChanged((_, nextProps) => void this.initProduct(nextProps.slug));
  }

  public isExperimentEnabled(experimentKey: string) {
    return this.controllerParams.flowAPI.experiments.enabled(experimentKey);
  }
}

import {IProductExtendedOption} from '../../../../../types/type';
import {RootStore} from '../../../stores/RootStore';
import {getOptionValidation} from '../../validator-service/getOptionValidation';

export const getProductOptionsWithValidation = (rootStore: RootStore): IProductExtendedOption[] => {
  return rootStore.$state.optionItems.map((option, index) => {
    return {
      ...option,
      validation: getOptionValidation(rootStore, option.title, index),
    };
  });
};

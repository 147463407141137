import {MeasurementUnitType} from '@wix/wixstores-graphql-schema/dist/es/src';

export const unitsTranslations = {
  [MeasurementUnitType.mg]: {
    abbr: 'PRODUCT_PAGE_BASE_UNIT_MG',
    singular: 'SR_PRODUCT_PAGE_UNIT_MILLIGRAM',
    plural: 'SR_PRODUCT_PAGE_UNIT_MILLIGRAMS',
  },
  [MeasurementUnitType.g]: {
    abbr: 'PRODUCT_PAGE_BASE_UNIT_G',
    singular: 'SR_PRODUCT_PAGE_UNIT_GRAM',
    plural: 'SR_PRODUCT_PAGE_UNIT_GRAMS',
  },
  [MeasurementUnitType.kg]: {
    abbr: 'PRODUCT_PAGE_BASE_UNIT_KG',
    singular: 'SR_PRODUCT_PAGE_UNIT_KILOGRAM',
    plural: 'SR_PRODUCT_PAGE_UNIT_KILOGRAMS',
  },
  [MeasurementUnitType.ml]: {
    abbr: 'PRODUCT_PAGE_BASE_UNIT_ML',
    singular: 'SR_PRODUCT_PAGE_UNIT_MILLILITER',
    plural: 'SR_PRODUCT_PAGE_UNIT_MILLILITERS',
  },
  [MeasurementUnitType.cl]: {
    abbr: 'PRODUCT_PAGE_BASE_UNIT_CL',
    singular: 'SR_PRODUCT_PAGE_UNIT_CENTILITER',
    plural: 'SR_PRODUCT_PAGE_UNIT_CENTILITERS',
  },
  [MeasurementUnitType.l]: {
    abbr: 'PRODUCT_PAGE_BASE_UNIT_L',
    singular: 'SR_PRODUCT_PAGE_UNIT_LITER',
    plural: 'SR_PRODUCT_PAGE_UNIT_LITERS',
  },
  [MeasurementUnitType.cbm]: {
    abbr: 'PRODUCT_PAGE_BASE_UNIT_CBM',
    singular: 'SR_PRODUCT_PAGE_UNIT_CUBICMETER',
    plural: 'SR_PRODUCT_PAGE_UNIT_CUBICMETERS',
  },
  [MeasurementUnitType.mm]: {
    abbr: 'PRODUCT_PAGE_BASE_UNIT_MM',
    singular: 'SR_PRODUCT_PAGE_UNIT_MILLIMETER',
    plural: 'SR_PRODUCT_PAGE_UNIT_MILLIMETERS',
  },
  [MeasurementUnitType.cm]: {
    abbr: 'PRODUCT_PAGE_BASE_UNIT_CM',
    singular: 'SR_PRODUCT_PAGE_UNIT_CENTIMETER',
    plural: 'SR_PRODUCT_PAGE_UNIT_CENTIMETERS',
  },
  [MeasurementUnitType.m]: {
    abbr: 'PRODUCT_PAGE_BASE_UNIT_M',
    singular: 'SR_PRODUCT_PAGE_UNIT_METER',
    plural: 'SR_PRODUCT_PAGE_UNIT_METERS',
  },
  [MeasurementUnitType.sqm]: {
    abbr: 'PRODUCT_PAGE_BASE_UNIT_SQM',
    singular: 'SR_PRODUCT_PAGE_UNIT_SQM',
    plural: 'SR_PRODUCT_PAGE_UNIT_SQMS',
  },
  [MeasurementUnitType.oz]: {
    abbr: 'PRODUCT_PAGE_BASE_UNIT_OZ',
    singular: 'SR_PRODUCT_PAGE_UNIT_OUNCE',
    plural: 'SR_PRODUCT_PAGE_UNIT_OUNCES',
  },
  [MeasurementUnitType.lb]: {
    abbr: 'PRODUCT_PAGE_BASE_UNIT_LB',
    singular: 'SR_PRODUCT_PAGE_UNIT_POUND',
    plural: 'SR_PRODUCT_PAGE_UNIT_POUNDS',
  },
  [MeasurementUnitType.floz]: {
    abbr: 'PRODUCT_PAGE_BASE_UNIT_FLOZ',
    singular: 'SR_PRODUCT_PAGE_UNIT_FLOZ',
    plural: 'SR_PRODUCT_PAGE_UNIT_FLOZS',
  },
  [MeasurementUnitType.pt]: {
    abbr: 'PRODUCT_PAGE_BASE_UNIT_PT',
    singular: 'SR_PRODUCT_PAGE_UNIT_PINT',
    plural: 'SR_PRODUCT_PAGE_UNIT_PINTS',
  },
  [MeasurementUnitType.qt]: {
    abbr: 'PRODUCT_PAGE_BASE_UNIT_QT',
    singular: 'SR_PRODUCT_PAGE_UNIT_QUART',
    plural: 'SR_PRODUCT_PAGE_UNIT_QUARTS',
  },
  [MeasurementUnitType.gal]: {
    abbr: 'PRODUCT_PAGE_BASE_UNIT_GAL',
    singular: 'SR_PRODUCT_PAGE_UNIT_GALLON',
    plural: 'SR_PRODUCT_PAGE_UNIT_GALLONS',
  },
  [MeasurementUnitType.in]: {
    abbr: 'PRODUCT_PAGE_BASE_UNIT_IN',
    singular: 'SR_PRODUCT_PAGE_UNIT_INCH',
    plural: 'SR_PRODUCT_PAGE_UNIT_INCHES',
  },
  [MeasurementUnitType.ft]: {
    abbr: 'PRODUCT_PAGE_BASE_UNIT_FT',
    singular: 'SR_PRODUCT_PAGE_UNIT_FOOT',
    plural: 'SR_PRODUCT_PAGE_UNIT_FEET',
  },
  [MeasurementUnitType.yd]: {
    abbr: 'PRODUCT_PAGE_BASE_UNIT_YD',
    singular: 'SR_PRODUCT_PAGE_UNIT_YARD',
    plural: 'SR_PRODUCT_PAGE_UNIT_YARDS',
  },
  [MeasurementUnitType.sqft]: {
    abbr: 'PRODUCT_PAGE_BASE_UNIT_SQF',
    singular: 'SR_PRODUCT_PAGE_UNIT_SQFOOT',
    plural: 'SR_PRODUCT_PAGE_UNIT_SQFEET',
  },
};

import {IProduct, IProductItem, UserInput} from '../types';
import {ProductType} from '../constants';
import {round} from 'lodash';

export const isShippingRequired = (product: IProduct) => {
  return product.productType !== ProductType.DIGITAL;
};

export const getCalculatedProductPrice = (product: IProduct | IProductItem, quantity: number) => {
  return round((product.comparePrice || product.price!) * quantity, 2);
};

export const getQuantity = (userInputs: UserInput) => {
  return userInputs.quantity[0];
};

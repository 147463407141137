/* eslint-disable @typescript-eslint/no-misused-promises */
import {RootStore} from '../../stores/RootStore';
import {handleQuantityChange} from '../handlers/handleQuantityChange';
import {handleCustomTextFieldsChange} from '../handlers/handleCustomTextFieldsChange';
import {handleOptionChange} from '../handlers/handleOptionChange';
import {handleSubscriptionChange} from '../handlers/handleSubscriptionChange';
import {handleAddToCartClick} from '../handlers/handleAddToCartClick';
import {handleBuyNowClick} from '../handlers/handleBuyNowClick';
import {handleSubscribeNowClick} from '../handlers/handleSubscribeNowClick';
import {handleNotifyMeClick} from '../handlers/handleNotifyMeClick';
import {handleWishlistClick} from '../handlers/handleWishlistClick';

export const handleInteractions = (rootStore: RootStore): void => {
  rootStore.services.components.quantityWidget.onChange(handleQuantityChange(rootStore));
  rootStore.services.components.ctaButtonsWidget.onAddToCartClick(handleAddToCartClick(rootStore));
  rootStore.services.components.ctaButtonsWidget.onBuyNowClick(handleBuyNowClick(rootStore));
  rootStore.services.components.ctaButtonsWidget.onSubscribeNowClick(handleSubscribeNowClick(rootStore));
  rootStore.services.components.ctaButtonsWidget.onNotifyMeClick(handleNotifyMeClick(rootStore));
  rootStore.services.components.ctaButtonsWidget.onWishlistClick(handleWishlistClick(rootStore));
  rootStore.services.components.customTextFieldsWidget.onChange(handleCustomTextFieldsChange(rootStore));
  rootStore.services.components.optionsNewWidget.onChange(handleOptionChange(rootStore));
  rootStore.services.components.subscription.onChange(handleSubscriptionChange(rootStore));
};

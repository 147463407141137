import {RootStore} from '../../../stores/RootStore';
import {SPECS} from '../../../../../specs';
import {RootWidgetComponentIds} from '../../../config/constants';

export const bindCtaButtonsProps = (rootStore: RootStore) => {
  const isMS3 = rootStore.controllerParams.flowAPI.experiments.enabled(SPECS.PRODUCT_PAGE_BLOCKS_MS3);
  const {$bind} = rootStore.controllerParams;
  return $bind(RootWidgetComponentIds.CtaButtons, {
    inStock: () => rootStore.$state.inStock,
    enableNotifyMe: () => rootStore.$state.isCollectingBackInStockRequests,
    isSubscribeNow: () => rootStore.$state.isSubscribeNow && isMS3,
    productId: () => rootStore.$state.product.id,
    isAddToCartInProgress: () => rootStore.$state.isAddToCartInProgress,
    deleted: () => false,
    isPreOrder: () => rootStore.$state.isPreOrder && isMS3,
    preOrderMessage: () =>
      rootStore.$state.isPreOrder && isMS3
        ? rootStore.$state.preOrderMessage ?? ''
        : rootStore.t.productPage.preOrderMessageWidget.placeholderText(),
    shouldShowPreOrderMessage: () => rootStore.$state.shouldShowPreOrderMessage && isMS3,
    labelOverrides: () => rootStore.wixCodeService.labelOverrides.cta,
  });
};

import {IViewerScriptExports} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/wixcode/createWixcodeExports';
import {ECOM_APP_DEF_ID, STORES_APP_DEF_ID} from '../../../constants/app';
import {RootStore} from './RootStore';

export const getEcomPublicApi = async (rootStore: RootStore): Promise<IViewerScriptExports | any> => {
  return rootStore.controllerParams.controllerConfig.wixCodeApi.site.getPublicAPI(ECOM_APP_DEF_ID);
};

export const getStoresPublicApi = async (rootStore: RootStore): Promise<IViewerScriptExports | any> => {
  return rootStore.controllerParams.controllerConfig.wixCodeApi.site.getPublicAPI(STORES_APP_DEF_ID);
};

import {action} from 'mobx';
import {HandleErrorFn} from './types';

export const getAppErrorHandler: HandleErrorFn = (rootStore) =>
  action((error: Error): Promise<void> => {
    const message = error?.message ? error.message : /* istanbul ignore next */ JSON.stringify(error);
    rootStore.$state.error = message;
    // eslint-disable-next-line no-console
    console.error(message);
    return Promise.resolve();
  });

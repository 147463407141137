import {RootStoreState} from '../../stores/RootStoreState';

export const getProductDescription = (state: RootStoreState): string => {
  const description = state.product.description;

  if (description && !description.startsWith('<p>')) {
    return `<p>${description}</p>`;
  }

  return description;
};

import {makeAutoObservable} from 'mobx';
import {RootWidgetComponentIds} from '../../../config/constants';
import {RootStore} from '../../../stores/RootStore';
import {getProductDescription} from '../../product-service/getProductDescription';
import {getRicoRichContent} from '../../../tests/utils/RichContentViewerUtils';
import {isRichContentEmpty} from '@wix/ricos';

const cleanHtml = (description: string = ''): string => {
  return description
    .replace(/<\/?[^>]+(?:>|$)/g, '')
    .replace(/&nbsp;/g, '')
    .trim();
};

export const bindDescriptionProps = (rootStore: RootStore) => {
  const {$bindAll} = rootStore.controllerParams;
  const {shouldUseRicoDescription} = rootStore.$state;

  const descStore = makeAutoObservable({
    get collapsibleDescription() {
      return getProductDescription(rootStore.$state);
    },
    get ricoRichContentDescription() {
      return getRicoRichContent(rootStore.$state.product.description);
    },
    get isCollapsibleEmpty() {
      return !cleanHtml(descStore.collapsibleDescription);
    },
    get isRicoRichContentEmpty() {
      return isRichContentEmpty(descStore.ricoRichContentDescription!);
    },
  });

  return $bindAll({
    [RootWidgetComponentIds.CollapsibleDescription]: {
      deleted: () => shouldUseRicoDescription || descStore.isCollapsibleEmpty,
      text: () => descStore.collapsibleDescription || '',
    },
    [RootWidgetComponentIds.RicoDescription]: {
      deleted: () => !shouldUseRicoDescription || descStore.isRicoRichContentEmpty,
      // @ts-expect-error
      content: () => descStore.ricoRichContentDescription || '',
    },
  });
};

import {RootStore} from '../../stores/RootStore';
import {TrackEventName} from '@wix/wixstores-client-core';

export const publishTrackViewContentTrackEvent = (rootStore: RootStore): void => {
  const state = rootStore.$state;

  return rootStore.wixCodeApi.window.trackEvent(
    TrackEventName.VIEW_CONTENT as any,
    {
      ...state.trackParams,
      dimension3: state.product.isInStock ? 'in-stock' : 'out-of-stock',
      quantity: undefined,
      variants: state.product.productItems.map((item) => ({
        id: item.id,
        optionsSelectionsIds: item.optionsSelections ?? [],
        price: item.price,
        sku: item.sku,
      })),
      options:
        state.product.options
          .map((option) => option.selections.map((selection) => ({id: selection.id, value: selection.value})))
          .flat() ?? [],
    } as any,
  );
};

import {RootStore} from '../../stores/RootStore';
import {clickAddToWishlistSf, clickRemoveFromWishlistSf} from '@wix/bi-logger-ec-sf/v2';
import {getCtaButtonClickHandler} from './getCtaButtonClickHandler';
import {WishlistStatus} from '@wix/wixstores-client-wishlist-button/src/components/app';

export const handleWishlistClick = (rootStore: RootStore) => {
  return getCtaButtonClickHandler<{status: string}>(
    rootStore,
    ({data}) => {
      const biParams = {
        hasOptions: rootStore.$state.product.options.length > 0,
        productId: rootStore.$state.product.id,
        productType: rootStore.$state.product.productType,
        isBlocks: true,
      };

      return rootStore.controllerParams.flowAPI.bi!.report(
        data.status === WishlistStatus.Added ? clickAddToWishlistSf(biParams) : clickRemoveFromWishlistSf(biParams),
      );
    },
    false,
  );
};

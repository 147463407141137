import {RootStore} from '../../stores/RootStore';
import {IProduct} from '../../../../types/type';
import {SPECS} from '../../../../specs';
import {RootWidgetComponentIds} from '../../config/constants';
import {getIsViewer} from '../platform-service/getIsViewer';

export const fetchProduct = async (rootStore: RootStore, slug: string): Promise<IProduct | undefined> => {
  try {
    const isMS3 = rootStore.controllerParams.flowAPI.experiments.enabled(SPECS.PRODUCT_PAGE_BLOCKS_MS3);
    const product = await fetchProductBySlug(slug, rootStore);

    product!.productItems = product!.productItems.map((item) => {
      return {
        ...item,
        availableForPreOrder: isMS3 ? item.availableForPreOrder : false,
      };
    });
    return product;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Failed to fetch product', e);
    navigateTo404(slug, rootStore);
  }
};

const fetchProductBySlug = (slug: string, rootStore: RootStore) => {
  if (slug) {
    return rootStore.ecomPublicApi.product.getExtendedProductBySlug(slug);
  }

  if (!getIsViewer(rootStore)) {
    return rootStore.ecomPublicApi.product.getDefaultProduct();
  }
};

const navigateTo404 = (slug: string, rootStore: RootStore) => {
  rootStore.controllerParams.$w(RootWidgetComponentIds.RootWidgetContainer).delete();
  rootStore.controllerParams.controllerConfig.wixCodeApi.seo.setSeoStatusCode(404);
  const location = rootStore.controllerParams.controllerConfig.wixCodeApi.location;
  // TODO: omrigr - 'error404' is the reserved slug for 404 page, this will be replaced when we will use router page
  location.to!(`${location.baseUrl}/error404`);
};

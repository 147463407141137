import {action} from 'mobx';
import {enforceQuantity} from '../user-input/enforceQuantity';
import {HandleQuantityChangeFn} from './types';

export const handleQuantityChange: HandleQuantityChangeFn = (rootStore) =>
  action(({data: quantity}) => {
    if (isNaN(quantity)) {
      return;
    }

    rootStore.$state.quantityInput = enforceQuantity(quantity);
    rootStore.services.components.quantityWidget.value = rootStore.$state.quantityInput;

    if (rootStore.wixCodeService.onQuantityChangedCallback) {
      rootStore.wixCodeService.onQuantityChangedCallback(rootStore.$state.quantityInput);
    }
  });

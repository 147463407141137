import {ICheckoutTotals, PaymentBreakdown} from '../types';
import {BreakdownTypes} from '../constants';

type GetPaymentBreakdownArgs = {
  checkoutTotals: ICheckoutTotals;
  calculatedPrice?: number;
  taxOnProduct?: boolean;
};

export const getPaymentBreakdown = ({
  checkoutTotals,
  calculatedPrice,
  taxOnProduct,
}: GetPaymentBreakdownArgs): PaymentBreakdown => {
  return {
    [BreakdownTypes.ItemsTotal]: (checkoutTotals?.subtotal ?? calculatedPrice).toString(),
    [BreakdownTypes.Shipping]: (checkoutTotals?.shipping ?? 0).toString(),
    [BreakdownTypes.Tax]: (taxOnProduct || !checkoutTotals?.tax ? 0 : checkoutTotals.tax).toString(),
    [BreakdownTypes.Discount]: (checkoutTotals?.discount ?? 0).toString(),
  };
};

import {RootStore} from '../../stores/RootStore';
import {TrackEventName} from '@wix/wixstores-client-core';

export const publishInitiateCheckoutTrackEvent = (rootStore: RootStore, checkoutId: string) => {
  const trackParams = rootStore.$state.trackParams;
  const {appDefId, origin, sku, type, brand, ...contents} = trackParams;
  const eventParams = {
    ...trackParams,
    checkoutId,
  };
  return rootStore.wixCodeApi.window.trackEvent(TrackEventName.INITIATE_CHECKOUT, {
    ...eventParams,
    contents: [contents],
  } as any);
};

import {getMediaUrl} from '@wix/wixstores-client-core/dist/es/src/media/mediaService';
import {
  FacebookSocialNetwork,
  PinterestSocialNetwork,
  SocialShareService,
  TwitterSocialNetwork,
  WhatsappSocialNetwork,
} from '@wix/wixstores-client-storefront-sdk/dist/es/src/services/ProductSocialShare/SocialShareService';
import {IMedia} from '@wix/wixstores-client-core/dist/src/media/types';
import {SocialVendor} from '../../../../constants/social';
import {IProduct} from '../../../../types/type';
import {ISocialShareLinks} from '../../config/types';

const socialNetworks = {
  [SocialVendor.WhatsApp]: WhatsappSocialNetwork,
  [SocialVendor.Facebook]: FacebookSocialNetwork,
  [SocialVendor.Twitter]: TwitterSocialNetwork,
  [SocialVendor.Pinterest]: PinterestSocialNetwork,
};

const createLinkToSpecificSocialShare = (shareType: string, product: IProduct, productUrl: string): string => {
  const vendor = new socialNetworks[shareType]();
  const productImage: IMedia = {
    mediaType: 'PHOTO',
    url: product.media?.[0]?.url,
    width: 100,
    height: 100,
  };
  const options = {asJpg: false, isSEOBot: true};
  const imageUrl = getMediaUrl(productImage, productImage, options);
  return new SocialShareService(vendor).getSocialNetworkUrl({
    title: product.name,
    url: productUrl,
    imageUrl,
  });
};

export const getSocialShareLinks = (product: IProduct, productUrl: string): ISocialShareLinks => {
  const socialsArr = ['whatsapp', 'facebook', 'pinterest', 'twitter'];
  const links = {};
  socialsArr.forEach((socialKind) => {
    links[socialKind] = createLinkToSpecificSocialShare(socialKind, product, productUrl);
  });
  return links;
};

import {RootStore} from '../../stores/RootStore';
import {FedopsInteractions} from '@wix/wixstores-client-storefront-sdk/dist/es/src/enums/fedopsInteractions';
import {getCtaButtonClickHandler} from './getCtaButtonClickHandler';
import {WidgetEventHandler} from './types';
import {createCheckout} from '../createCheckout';

export const handleSubscribeNowClick = (rootStore: RootStore): WidgetEventHandler<any> => {
  return getCtaButtonClickHandler(rootStore, () =>
    rootStore.reportFedopsInteraction(FedopsInteractions.Subscribe, () =>
      createCheckout(rootStore, {withNavigateToCheckout: true}),
    ),
  );
};

import {RootStore} from '../../../stores/RootStore';
import {getSocialShareLinks} from '../../product-service/getSocialShare';
import {SocialVendor} from '../../../../../constants/social';
import {makeAutoObservable} from 'mobx';
import {RootWidgetComponentIds} from '../../../config/constants';

export const bindSocialShareWidgetProps = (rootStore: RootStore) => {
  const {$bind} = rootStore.controllerParams;
  const socialShareStore = makeAutoObservable({
    get links() {
      return getSocialShareLinks(rootStore.$state.product, rootStore.$state.absoluteProductUrl);
    },
  });
  return $bind(RootWidgetComponentIds.SocialShare, {
    facebookLink: () => socialShareStore.links[SocialVendor.Facebook],
    whatsappLink: () => socialShareStore.links[SocialVendor.WhatsApp],
    twitterLink: () => socialShareStore.links[SocialVendor.Twitter],
    pinterestLink: () => socialShareStore.links[SocialVendor.Pinterest],
    deleted: () => false,
  });
};

import {RootStore} from '../../../stores/RootStore';
import {IProduct} from '../../../../../types/type';
import {ContextualProduct} from '@wix/wixstores-client-storefront-sdk/dist/es/src/services/types';
import {makeAutoObservable} from 'mobx';
import {RootWidgetComponentIds} from '../../../config/constants';

const getCurrentProductIndex = (contextualProducts: ContextualProduct[], product: IProduct): number =>
  contextualProducts.findIndex((contextualProduct) => contextualProduct.slug === product.urlPart);

export const bindNavigationWidgetProps = (rootStore: RootStore) => {
  const {
    $state: {product, contextualProducts},
  } = rootStore;

  const {$bind} = rootStore.controllerParams;
  const navStore = makeAutoObservable({
    get currIndex() {
      return getCurrentProductIndex(contextualProducts, product);
    },
    get isFirst() {
      return navStore.currIndex === 0;
    },
    get isLast() {
      return navStore.currIndex === contextualProducts.length - 1;
    },
    get isProductExists() {
      return navStore.currIndex !== -1;
    },
    get prevLink() {
      return !navStore.isProductExists || navStore.isFirst
        ? undefined
        : contextualProducts[navStore.currIndex - 1].relativeUrl;
    },
    get nextLink() {
      return !navStore.isProductExists || navStore.isLast
        ? undefined
        : contextualProducts[navStore.currIndex + 1].relativeUrl;
    },
  });

  return $bind(RootWidgetComponentIds.Navigation, {
    data: () => ({
      prevLink: navStore.prevLink,
      nextLink: navStore.nextLink,
    }),
    deleted: () => false,
  });
};
